import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6';

const header = document.querySelector('.header');
const hamburger = document.querySelector('.header__hamburger');
const nav = document.querySelector('.header__nav');

let isMenuOpened = false;

window.addEventListener('scroll', () => {
  if (scrollY > window.innerHeight) {
    header.classList.add('filled');
  } else {
    header.classList.remove('filled');
  }
});

function openMenu() {
  header.classList.add('menu-opened');
  hamburger.classList.add('active');
  disableBodyScroll(nav);
  isMenuOpened = true;
}

function closeMenu() {
  header.classList.remove('menu-opened');
  hamburger.classList.remove('active');
  enableBodyScroll(nav);
  isMenuOpened = false;
}

hamburger.addEventListener('click', () => isMenuOpened ? closeMenu() : openMenu());
