const button = document.querySelector('.scroll-to-top');
const footer = document.querySelector('.footer');

window.addEventListener('scroll', () => {
  if (footer.getBoundingClientRect().top < window.innerHeight) {
    button.classList.add('absolute');
  } else {
    button.classList.remove('absolute');
  }
});
